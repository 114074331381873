import React, { useState } from "react";

const fields = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    required: true
  },
  {
    name: 'company',
    type: 'text',
    label: 'Company',
    required: false
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    required: true,
    size: "sm:col-span-2"
  },
  {
    name: 'subject',
    type: 'text',
    label: 'Subject',
    required: true,
    size: "sm:col-span-2"
  },
  {
    name: 'message',
    type: 'textarea',
    label: 'Message',
    required: true,
    size: "sm:col-span-2"
  }
]

const Field = ({ field, fieldChanged, value }) => {
  return (
    <div className={field.size}>
      <label htmlFor={field.name} className="inline-block text-gray-800 text-sm sm:text-base mb-2">{field.label}{field.required && '*'}</label>
      {field.type !== 'textarea' && <input
        name={field.name}
        className="w-full bg-gray-50 text-gray-800 border focus:ring ring-magicite-light rounded outline-none transition duration-100 px-3 py-2"
        type={field.type}
        id={field.name}
        value={value}
        onChange={(e) => fieldChanged(e)}
        required={field.required}
      />}
      {field.type === 'textarea' && <textarea
        name={field.name}
        className="w-full h-64 bg-gray-50 text-gray-800 border focus:ring ring-magicite-light rounded outline-none transition duration-100 px-3 py-2"
        type={field.type}
        id={field.name}
        value={value}
        onChange={(e) => fieldChanged(e)}

      />}
    </div>
  )
}

const Contact = ({open, showContact}) => {
  if (!open) return (<></>);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const emptyForm = {
    name: "",
    company: "",
    email: "",
    subject: "",
    message: ""
  };

  const [formData, setFormData] = useState({
   ...emptyForm
  });

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

  // Handles the post process to Netlify so we can access their serverless functions
  const handleSubmit = (event) => {
    event.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...formData
      })
    }).then(() => {
      setFormData({...emptyForm});
      setFormSubmitted(true);
      setTimeout(() => {
        showContact(false);
        setFormSubmitted(false);
      }, 2000);
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl bg-white p-16 rounded z-50">
        {!formSubmitted && <form className="max-w-screen-md grid sm:grid-cols-2 gap-4 mx-auto" name="contact" onSubmit={handleSubmit} data-netlify="true" method="POST">

          {fields.map((field) => <Field field={field} fieldChanged={(e) => setFormData({...formData, [field.name]: e.target.value })} value={formData[field.name]} />)}
          <input type="hidden" name="form-name" value="contact" />
          <div className="sm:col-span-2 flex justify-between items-center">
            <button type="submit" href="#" className="inline-block bg-transparent hover:bg-magicite active:bg-magicite-dark border border-magicite hover:border-white focus-visible:ring ring-magicite-light text-magicite hover:text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Send</button>

            <span className="text-gray-500 text-sm">*Required</span>
          </div>
        </form>}
        {formSubmitted && <h2>Sent! Thanks for your inquiry.</h2>}
      </div>
      <div onClick={() => showContact(false)} className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default Contact;
