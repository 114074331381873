import React from "react";

const HeaderLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440.01 90">
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_5" data-name="Layer 5">
            <path className="cls-1"
                d="M59.91,0H30.09A30.18,30.18,0,0,0,0,30.09V59.91A30.18,30.18,0,0,0,30.09,90H59.91A30.18,30.18,0,0,0,90,59.91V30.09A30.18,30.18,0,0,0,59.91,0ZM45,9.87c10.38,0,19.06,5.73,21.43,13.45a5.62,5.62,0,0,0-3.85-1.54L45,28.25,27.42,21.78a5.62,5.62,0,0,0-3.85,1.54C25.94,15.6,34.62,9.87,45,9.87ZM9.87,45c0-10.38,5.73-19.06,13.45-21.43a5.62,5.62,0,0,0-1.54,3.85L28.25,45,21.78,62.58a5.62,5.62,0,0,0,1.54,3.85C15.6,64.06,9.87,55.38,9.87,45ZM45,80.13c-10.38,0-19.06-5.73-21.43-13.45a5.62,5.62,0,0,0,3.85,1.54L45,61.75l17.58,6.47a5.62,5.62,0,0,0,3.85-1.54C64.06,74.4,55.38,80.13,45,80.13Zm21.68-13.7a5.62,5.62,0,0,0,1.54-3.85L61.75,45l6.47-17.58a5.62,5.62,0,0,0-1.54-3.85c7.72,2.37,13.45,11,13.45,21.43S74.4,64.06,66.68,66.43Z" />
            <rect className="cls-1" x="112.86" width="1" height="90" />
            <path className="cls-1"
                d="M140.49,60.09h1l3.58,10.25h-1.22L143.05,68h-4.51l-.88,2.33h-1.13ZM138.91,67h3.82l-1.79-5.41h0Z" />
            <path className="cls-1"
                d="M160.61,60.09h2.67c2.59,0,5.45,1.37,5.45,5.08s-2.84,5.17-5.6,5.17h-2.52Zm1.69,9.26c3.16,0,5.27-1,5.27-4,0-2.77-1.68-4.22-4.57-4.22h-1.3v8.27Z" />
            <path className="cls-1" d="M187.87,70.34h-1L183.3,60.09h1.24l2.85,8.61h0l3.25-8.61h1.16Z" />
            <path className="cls-1" d="M207.23,70.34V60.09h1.09V70.34Z" />
            <path className="cls-1"
                d="M228.07,60.91c-1.26,0-2.06.6-2.06,1.59,0,1.15,1.36,1.5,2.71,2s2.66,1.14,2.66,2.85a3.23,3.23,0,0,1-3.47,3.17,4.72,4.72,0,0,1-3.6-1.45l.82-.73a3.42,3.42,0,0,0,2.8,1.18,2,2,0,0,0,2.3-2c0-1.23-1.17-1.69-2.42-2.15-1.41-.53-2.91-1.06-2.91-2.73a2.69,2.69,0,0,1,3-2.74,4.88,4.88,0,0,1,3,1l-.72.79A3.4,3.4,0,0,0,228.07,60.91Z" />
            <path className="cls-1"
                d="M252.31,70.52a5.31,5.31,0,1,1,5.23-5.31A5.23,5.23,0,0,1,252.31,70.52Zm0-9.61a4.31,4.31,0,1,0,4.08,4.3A4.11,4.11,0,0,0,252.31,60.91Z" />
            <path className="cls-1"
                d="M274.85,70.34h-1.08V60.09h2.94c1.54,0,3.4.8,3.4,2.88a2.83,2.83,0,0,1-2.66,2.87c1.11,1.53,2.76,3.75,3.32,4.5h-1.35l-3.22-4.47h-1.35Zm0-5.46h1.5c1.59,0,2.6-.54,2.6-1.88,0-1.15-.93-1.92-2.31-1.92h-1.79Z" />
            <path className="cls-1" d="M299,66v4.38h-1.09V65.76l-3.13-5.67h1.33l2.43,4.58h.05L301,60.09h1.25Z" />
            <path className="cls-1"
                d="M340.32,65.8v-1h3.77v3.81a5.46,5.46,0,0,1-9.61-3.4,5.36,5.36,0,0,1,5.54-5.3,5.19,5.19,0,0,1,3.8,1.73l-.79.62a4.13,4.13,0,0,0-3-1.35,4.19,4.19,0,0,0-4.36,4.3A4.08,4.08,0,0,0,340,69.52a4.27,4.27,0,0,0,3-1.13V65.8Z" />
            <path className="cls-1"
                d="M361.72,70.34h-1.08V60.09h2.94c1.54,0,3.4.8,3.4,2.88a2.84,2.84,0,0,1-2.67,2.87c1.12,1.53,2.77,3.75,3.33,4.5h-1.35l-3.22-4.47h-1.35Zm0-5.46h1.49c1.6,0,2.61-.54,2.61-1.88,0-1.15-.94-1.92-2.31-1.92h-1.79Z" />
            <path className="cls-1"
                d="M387.7,70.52a5.31,5.31,0,1,1,5.23-5.31A5.23,5.23,0,0,1,387.7,70.52Zm0-9.61a4.31,4.31,0,1,0,4.08,4.3A4.11,4.11,0,0,0,387.7,60.91Z" />
            <path className="cls-1"
                d="M416.85,65.52c0,3.23-1.16,5-3.93,5s-3.86-1.77-3.86-5V60.09h1.08v5.84c0,2.42,1.09,3.59,2.81,3.59s2.82-1.17,2.82-3.59V60.09h1.08Z" />
            <path className="cls-1"
                d="M436.49,60.09c1.63,0,3.52.8,3.52,3s-1.88,3-3.52,3h-1.87v4.23h-1.09V60.09Zm-1.87,5h1.5c1.56,0,2.73-.51,2.73-2,0-1.27-1-2-2.43-2h-1.8Z" />
            <path className="cls-1"
                d="M174.54,51V37.12L161.09,45h-1.21l-13.37-7.79V51h-10V21.25h1.25L160.53,34.7l22.75-13.45h1.26V51Z" />
            <path className="cls-1"
                d="M225.59,51l-2.77-4.63H203.49L200.68,51H188.43l19.73-29.38h10.21L238.05,51ZM213.18,30l-5.67,9.47H218.8Z" />
            <path className="cls-1"
                d="M259.94,51.79c-11.37,0-22.66-4-22.66-15.36,0-11.94,12.84-15.36,22.66-15.36,7,0,13,1,18.26,3.42l-1.95,6.83a39.67,39.67,0,0,0-15.4-3c-8.86,0-13.23,3.07-13.23,8.22,0,4.93,4.67,8.21,13.19,8.21A32,32,0,0,0,268,44v-8.4h10V49.15A59.48,59.48,0,0,1,259.94,51.79Z" />
            <path className="cls-1" d="M283.7,51V21.85h10V51Z" />
            <path className="cls-1"
                d="M321.07,51.79c-11.46,0-22.66-4-22.66-15.36,0-12,13.19-15.36,22.66-15.36,6.93,0,12.55.87,18.26,3.42l-1.95,7A39.45,39.45,0,0,0,322,28.43c-8.86,0-13.23,3.11-13.23,8s4.67,8,13.19,8a38.41,38.41,0,0,0,15.44-3.07l2,7A46.62,46.62,0,0,1,321.07,51.79Z" />
            <path className="cls-1" d="M343.88,51V21.85h10V51Z" />
            <path className="cls-1" d="M383.76,29.42V51h-9.95V29.42H357.89V21.85h41.83v7.57Z" />
            <path className="cls-1" d="M403.66,51V21.85H440v6.92h-26.3v3.46H440v6.62h-26.3v5.24H440V51Z" />
        </g>
    </g>
  </svg>
)


const Header = ({ onHideNav, onShowNav, showNav, siteTitle, showContact, services, team }) => (
  <header className="flex flex-wrap sm:flex-wrap justify-between items-center py-4 md:py-8">
    <a href="/" className="inline-flex items-center text-black-800 text-2xl md:text-3xl font-bold gap-2.5" aria-label="logo">
      <div className="w-80 inline-block">
        <HeaderLogo/>
      </div>
    </a>

    <nav className="flex w-full md:w-96 uppercase tracking-widest justify-between mt-4 md:mt-0 px-4 sm:px-0">
      {services}
      {team}
      {/* <a href="#Services" className="ml-0 text-black hover:text-magicite active:text-magicite-dark text-lg transition duration-100">Services</a> */}
      {/* <a href="#Team" className="ml-16 text-black hover:text-magicite active:text-magicite-dark text-lg transition duration-100">Team</a> */}
      <a onClick={() => showContact(true)} style={{cursor: 'pointer'}} className="text-black hover:text-magicite active:text-magicite-dark text-lg transition duration-100 self-end">Contact</a>
    </nav>
  </header>
)

export default Header;
