import React from "react";

const Magicite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.43 227.43">
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path className="cls-1" style={{fill: "#34679a"}}
                d="M151.38,0H76.05A76.27,76.27,0,0,0,0,76.05v75.33a76.27,76.27,0,0,0,76.05,76.05h75.33a76.27,76.27,0,0,0,76.05-76.05V76.05A76.27,76.27,0,0,0,151.38,0ZM113.72,24.93c26.22,0,48.17,14.49,54.14,34A14.2,14.2,0,0,0,158.14,55L113.72,71.38,69.29,55a14.2,14.2,0,0,0-9.72,3.88C65.54,39.42,87.5,24.93,113.72,24.93ZM24.93,113.72c0-26.22,14.49-48.18,34-54.15A14.2,14.2,0,0,0,55,69.29l16.34,44.43L55,158.14a14.2,14.2,0,0,0,3.88,9.72C39.42,161.89,24.93,139.94,24.93,113.72Zm88.79,88.78c-26.22,0-48.18-14.48-54.15-34a14.15,14.15,0,0,0,9.72,3.88l44.43-16.34,44.42,16.34a14.15,14.15,0,0,0,9.72-3.88C161.89,188,139.94,202.5,113.72,202.5Zm54.79-34.64a14.15,14.15,0,0,0,3.88-9.72l-16.34-44.42,16.34-44.43a14.15,14.15,0,0,0-3.88-9.72c19.51,6,34,27.93,34,54.15S188,161.89,168.51,167.86Z" />
        </g>
    </g>
</svg>
);

export default Magicite;
